import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useFeedPostQuery} from "../../types/gqlReactTypings.generated.d";

export const FeedPost = () => {
    const errorString = 'Something is wrong!';
    const queryParameters = new URLSearchParams(window.location.search);
    const feedPostId = parseInt(queryParameters.get("feedPostId") || '', 10);
    const [errorMessage, setErrorMessage] = useState('');
    const {data, loading, error} = useFeedPostQuery({variables: {feedPostId}});
    const url = window.location.href;
    useEffect(() => {
        if (!loading && error) {
            setErrorMessage(errorString);
        }
    }, [data, loading, error]);
    return (
        <>
            <Helmet>
                <meta property="og:type" content="video"/>
                <meta property="og:url" content={url}/>
                <meta property="og:title" content="Hey, join our team!"/>
                <meta property="og:description" content={data?.feedPost.description}/>
                <meta property="og:image" content={data?.feedPost.thumbnailUrl}/>
                <meta property="og:video" content={data?.feedPost.imageUrl}/>
                <meta property="og:video:secure_url" content={data?.feedPost.imageUrl}/>
                <meta property="og:video:type" content="video/mp4"/>
                <meta property="og:video:width" content="720"/>
                <meta property="og:video:height" content="1280"/>
            </Helmet>
            <div className="container">
                <div className="header">
                    <a href="https://www.ultrainsports.com/" target="_blank">
                        <img
                            src="https://static.wixstatic.com/media/8c0d9b_421b49b10c274d31aba09ddcee23b485~mv2.png/v1/fill/w_217,h_48,al_c,q_85,enc_auto/Group%20123.png"
                            alt="" width="217" height="48"/>
                    </a>
                </div>
                <div className="content">
                    <div className="card">
                        <div className="card-header error-message"
                             style={{display: errorMessage ? 'block' : 'none'}}>&#128542;</div>
                        <div className="card-content error-message"
                             style={{display: errorMessage ? 'block' : 'none'}}>{errorMessage}</div>
                        <div className="card-header"
                             style={{display: !errorMessage ? 'block' : 'none', fontSize: '25px', color: '#fff'}}>
                            {data?.feedPost.description}
                        </div>
                        <div className="card-content"
                             style={{display: !errorMessage ? 'block' : 'none', padding: 0, lineHeight: 0}}>
                            {data?.feedPost && <video controls style={{maxWidth: '100%', borderRadius: '0 0 8px 8px'}}>
                                <source src={data?.feedPost.imageUrl} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>}
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div style={{margin: '38px 0px 28px 0'}}>
                        <a href="https://www.ultrainsports.com/" target="_blank">
                            <img
                                src="https://static.wixstatic.com/media/8c0d9b_e6a688b7c23149d7a81050dd223929cf~mv2.png/v1/fill/w_269,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%20380.png"
                                alt="Ultrain Sports"
                                style={{
                                    width: '269px',
                                    height: '60px',
                                    'objectFit': 'cover',
                                    'objectPosition': '50% 50%'
                                }}
                                width="269" height="60"/>
                        </a>
                    </div>
                    <div style={{
                        gap: '21px',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginBottom: 28
                    }}>
                        <a href="https://www.facebook.com/Ultrainsports/" target="_blank">
                            <img
                                src="https://static.wixstatic.com/media/8c0d9b_2838907915974918b2794a0d21a71827~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/facebook%20(13).png"
                                alt="facebook"
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    'objectFit': 'cover',
                                    'objectPosition': '50% 50%'
                                }}
                                width="30" height="30"/>
                        </a>
                        <a href="https://www.instagram.com/ultrainsports/" target="_blank">
                            <img
                                src="https://static.wixstatic.com/media/8c0d9b_bab53ba5d49f478ca102b5356cc1850d~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/instagram%20(11).png"
                                alt="instagram"
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    'objectFit': 'cover',
                                    'objectPosition': '50% 50%'
                                }}
                                width="30" height="30"/>
                        </a>
                        <span>
                        <img
                            src="https://static.wixstatic.com/media/8c0d9b_283bd24f57254a1da1bbd907af90d748~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/twitter%20(6).png"
                            alt="twitter"
                            style={{width: '30px', height: '30px', 'objectFit': 'cover', 'objectPosition': '50% 50%'}}
                            width="30" height="30"/>
                    </span>
                        <a href="https://www.youtube.com/channel/UCn0HTdAh_nVCg2rnkS2bC6w" target="_blank">
                            <img
                                src="https://static.wixstatic.com/media/8c0d9b_7855582c73ee4c939a7b68edad965d64~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/youtube%20(8).png"
                                alt="youtube"
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    'objectFit': 'cover',
                                    'objectPosition': '50% 50%'
                                }}
                                width="30" height="30"/>
                        </a>
                    </div>
                    <div style={{
                        fontFamily: 'wf_2',
                        fontSize: '14px',
                        textAlign: 'center',
                        color: '#585858',
                    }}>
                        © All rights reserved | 2023 ULTRAIN SPORTS
                    </div>
                </div>
            </div>
        </>
    );
};
